// roofConfig.js
export let leftOverhang = 0;
export let rightOverhang = 0;
export let FrontOverhang = 0;
export let RearOverhang = 0;


export function setLeftOverhang(value) {
  leftOverhang = value;
}

export function setRightOverhang(value) {
  rightOverhang = value;
}

export function setFrontOverhang(value) {
  FrontOverhang = value;
}

export function setRearOverhang(value) {
  RearOverhang = value;
}


export function resetAllOverhangs(){


  setLeftOverhang(0);
  setRightOverhang(0);
  setFrontOverhang(0);
  setRearOverhang(0);

}