import React from 'react';
import * as Three from 'three';
import {loadObjWithMaterial, loadObjWithMaterial2} from '../../utils/load-obj';
import path from 'path';

let cached3DWindow = null;

export default {
  name: "window",
  prototype: "holes",

  info: {
    title: "window",
    tag: ['window'],
    description: "Window",
    image: require('./window.png'),
    visibility: {
      catalog: false,
      layerElementsVisible: false
    }
  },

  properties: {
    width: {
      label: "Width",
      type: "length-measure",
      defaultValue: {
        length: 90
      }
    },
    height: {
      label: "Height",
      type: "length-measure",
      hide: true,
      defaultValue: {
        length: 100
      }
    },
    altitude: {
      label: "Altitude",
      type: "length-measure",
      hide: true,
      defaultValue: {
        length: 90
      }
    },
    thickness: {
      label: "Thickness",
      type: "length-measure",
      hide: true,
      defaultValue: {
        length: 20
      }
    }
  },

  render2D: function (element, layer, scene) {
    const STYLE_HOLE_BASE = {stroke: "#000", strokeWidth: "3px", fill: "#000"};
    const STYLE_HOLE_SELECTED = {stroke: "#0096fd", strokeWidth: "3px", fill: "#0096fd", cursor: "move"};
    //let line = layer.lines.get(hole.line);
    //let epsilon = line.properties.get('thickness') / 2;

    // let epsilon = 3;
    // const epsilon = 7;
    let epsilon = 10;

    let holeWidth = element.properties.get('width').get('length');
    let holePath = `M${0} ${ -epsilon}  L${holeWidth} ${-epsilon}  L${holeWidth} ${epsilon}  L${0} ${epsilon}  z`;
    let holeStyle = element.selected ? STYLE_HOLE_SELECTED : STYLE_HOLE_BASE;
    let length = element.properties.get('width').get('length');
    return (
      <g transform={`translate(${-length / 2}, 0)`}>
        <path key="1" d={holePath} style={holeStyle}/>
        <line key="2" x1={holeWidth / 2} y1={-10 - epsilon} x2={holeWidth / 2} y2={10 + epsilon} style={holeStyle}/>
      </g>
    );
  },

  // render3D: function (element, layer, scene) {
  //   let onLoadItem = (object) => {
  //     let boundingBox = new Three.Box3().setFromObject(object);

  //     let initialWidth = boundingBox.max.x - boundingBox.min.x;
  //     let initialHeight = boundingBox.max.y - boundingBox.min.y;
  //     let initialThickness = boundingBox.max.z - boundingBox.min.z;

  //     if (element.selected) {
  //       let box = new Three.BoxHelper(object, 0x99c3fb);
  //       box.material.linewidth = 2;
  //       box.material.depthTest = false;
  //       box.renderOrder = 1000;
  //       object.add(box);
  //     }

  //     let width = element.properties.get('width').get('length');
  //     let height = element.properties.get('height').get('length');
  //     let thickness = element.properties.get('thickness').get('length');

  //     object.scale.set(width / initialWidth, height / initialHeight,
  //       thickness / initialThickness);

  //     return object;
  //   };

  //   if(cached3DWindow) {
  //     return Promise.resolve(onLoadItem(cached3DWindow.clone()));
  //   }

  //   let mtl = require('./window.mtl');
  //   let obj = require('./window.obj');
  //   let img = require('./texture.png');

  //   return loadObjWithMaterial2(mtl, obj, path.dirname(img) + '/')
  //     .then(object => {
  //       cached3DWindow = object;
  //       return onLoadItem(cached3DWindow.clone())
  //     })
  // }


  render3D: function (element, layer, scene) {
    let w = element.properties.getIn(["width", "length"]);
    let h = element.properties.getIn(["height", "length"]);
    let WallThickeness = element.properties.getIn(["thickness", "length"]);
    let d = 7;

    let frame = new Three.Group();

    let materialFrame = new Three.MeshBasicMaterial({
      color: 0x383e42,
    });
    let materialGlass = new Three.MeshBasicMaterial({
      color: 0x6e95e1,
    });    

    function createOuterFrame(width, height, depth, position) {
      let geometry = new Three.BoxGeometry(width, height, depth);
      let mesh = new Three.Mesh(geometry, materialFrame);
      mesh.position.set(position.x, position.y, position.z);

      frame.add(mesh);
    }
    CreateOuterFrameBars(w, d, WallThickeness, h);

    function CreateOuterFrameBars(w1, d1, WallThickeness1, h1) {
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: h1 / 2 - d1 / 2, z: 0 });
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: -h1 / 2 + d1 / 2, z: 0 });
      createOuterFrame(d1, h1 - 2 * d1, WallThickeness1, {x: -w1 / 2 + d1 / 2,y: 0,z: 0,});
      createOuterFrame(d1, h1 - 2 * d1, WallThickeness1, {x: w1 / 2 - d1 / 2,y: 0,z: 0,});


      //inner top and bottom
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: (h1 / 2 - d1 / 2)-d1, z: 0 });
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: (-h1 / 2 + d1 / 2)-d1, z: 0 });

    }

    function CreateInnerFrameBars(w1, d1, WallThickeness1, h1, positionX) {


//left and right bars
      createOuterFrame(d1, h1, WallThickeness1, {x: -positionX,y: 0-(d1/2),z: 0,});
      createOuterFrame(d1, h1, WallThickeness1, {x: positionX,y: 0-(d1/2),z: 0,});

      

    }

    
    function createFrameGlass(width, height, depth) {
      let geometryGlass = new Three.BoxGeometry(width, height, depth / 5);
      let meshGlass = new Three.Mesh(geometryGlass, materialGlass);
      frame.add(meshGlass);
    }

    createFrameGlass(w, h, WallThickeness / 5);

    
    function createInnerFrames(d1, WallThickeness1, h1, totalWidth, panelCount) {

      let panelWidth = (totalWidth-(d*2)) / panelCount;
      for (let i = 0; i < panelCount; i++) {

        let positionX = -totalWidth / 2 + panelWidth / 2 + i * panelWidth;

        CreateInnerFrameBars(panelWidth, d1, WallThickeness1, h1-(d1*2) , d1+positionX+(panelWidth/2));

      }
    }

    createInnerFrames(d, WallThickeness, h, w, 1); 



    return Promise.resolve(frame);
  },



};