import { ElementsFactories } from "react-planner";
// import claddingTextures from "../../../../../src/catalog/factories/claddingConfig";

const info = {
  title: "External Wall",
  tag: ["wall"],
  description: "Wall with bricks or painted",
  image: require("./wall.png"),
  PTP_ID: "wallExternal01",
  visibility: {
    catalog: true,
    layerElementsVisible: true,
  },
};

const textures = {
  cedarVertical: {
    name: "Western Redwood Cedar",
    uri: require("./textures/Vertical-Cedar.jpeg"),
    lengthRepeatScale: 0.007,
    heightRepeatScale: 0.001,
    normal: {
      uri: require("./textures/normal_Vertical_Western_Red_Cedar.png"),
      lengthRepeatScale: 0.007,
      heightRepeatScale: 0.001,
      normalScaleX: 0.8,
      normalScaleY: 0.8,
    },
  },
  cedarHorizontal: {
    name: "Western Redwood Cedar Horizontal",
    uri: require("./textures/HorizontalCedar.jpeg"),
    lengthRepeatScale: 0.001,
    heightRepeatScale: 0.007,
  },
  larchVertical: {
    name: "Larch - Vertical",
    uri: require("./textures/Vertical-Larch.jpg"),
    lengthRepeatScale: 0.007,
    heightRepeatScale: 0.001,
  },
  larchHorizontal: {
    name: "Larch - Horizontal",
    uri: require("./textures/Horizontal-Larch.jpg"),
    lengthRepeatScale: 0.001,
    heightRepeatScale: 0.007,
  },
  thermoWoodVertical: {
    name: "ThermoWood - Vertical",
    uri: require("./textures/Vertical-ThermoWood.jpg"),
    lengthRepeatScale: 0.007,
    heightRepeatScale: 0.001,
  },
  thermoWoodHorizontal: {
    name: "ThermoWood - Horizontal",
    uri: require("./textures/Horizontal-ThermoWood.jpg"),
    lengthRepeatScale: 0.001,
    heightRepeatScale: 0.007,
  },

  painted: {
    name: "Painted",
    uri: require("./textures/painted.jpg"),
    lengthRepeatScale: 0.01,
    heightRepeatScale: 0.01,
    normal: {
      uri: require("./textures/painted-normal.jpg"),
      lengthRepeatScale: 0.01,
      heightRepeatScale: 0.01,
      normalScaleX: 0.4,
      normalScaleY: 0.4,
    },
  },
};

export default ElementsFactories.WallFactory("externalWall", info, textures);
// export default ElementsFactories.WallFactory('externalWall', info, claddingTextures);
