import { func } from "prop-types";

export let overallWidth = 0;
export let overallDepth = 0;

export let overallFloorArea = 0;


export function setOverallWidth(value) {
  overallWidth = (value-2.2000000000001).toFixed(2);
  setOverallFloorArea();
}


export function setOverallDepth(value) {
  overallDepth = (value-2.20000000000005).toFixed(2);
  setOverallFloorArea();
}


export function setOverallFloorArea() { 
  overallFloorArea = ((overallWidth * overallDepth)/10000).toFixed(2);
}

export function getOverallWidth() {
  return overallWidth;
}

export function getOverallDepth() {
  return overallDepth;
}

export function getOverallFloorArea() {
  return overallFloorArea;
}

export function validateBuildingForExport(){

  let boolOutput = false;

  if(overallWidth > 0 && overallDepth > 0){
    boolOutput = true;
  }
  else{
    boolOutput = false;
  }

  return boolOutput;
}