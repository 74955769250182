import { Catalog } from 'react-planner';

let catalog = new Catalog();


const importModules = (r) => {
  return r.keys().map((key) => {
    return r(key).default; // Assuming that each module has a default export
  });
};

const areaModules = importModules(require.context('./areas/', true, /planner-element\.jsx$/));
const linesModules = importModules(require.context('./lines/', true, /planner-element\.jsx$/));
const holesModules = importModules(require.context('./holes/', true, /planner-element\.jsx$/));
const itemsModules = importModules(require.context('./items/', true, /planner-element\.jsx$/));

areaModules.forEach((area) => catalog.registerElement(area));
linesModules.forEach((line) => catalog.registerElement(line));
holesModules.forEach((hole) => catalog.registerElement(hole));
itemsModules.forEach((item) => catalog.registerElement(item));


export default catalog;
