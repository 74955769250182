import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import OverHangButton from './overhangButton';
import PropTypes from "prop-types";
import * as Three from "three";
import { parseData, updateScene } from "./scene-creator";
import { disposeScene } from "./three-memory-cleaner";
import OrbitControls from "./libs/orbit-controls";
import diff from "immutablediff";
import * as SharedStyle from "../../styles/shared-style";
import ReactPlannerContext from "../../utils/react-planner-context";
import { usePrevious } from "@uidotdev/usehooks";
import { THREE, DataUtils, SRGBColorSpace } from './landscape/src/base/three-defs.js';
import { fragmentShader, vertexShader } from './shaders/Grassshaders';
import { BoxGeometry } from "three-legacy";

import {
  leftOverhang,
  rightOverhang,
  FrontOverhang,
  RearOverhang,

} from './roofConfig.js';

import {
  setOverallDepth,
  setOverallWidth,
} from './buildingDataExport';

import {
  setTextureMultiplierHorizontal,
  setTextureMultiplierVertical,
} from './textureScaler';


let currentRoof = null;
let mouseDownEvent = null;
let mouseUpEvent = null;
let cameraP = null;
let scene3DP = null;
let planDataP = null;
const lastMousePosition = {};
let renderingID = "";
let PLANE_SIZE = 3000;
let BLADE_COUNT = PLANE_SIZE * 50;


const Scene3DViewer = (props) => {
  const previousProps = usePrevious(props);
  let canvasWrapper = useRef(null);
  const actions = useContext(ReactPlannerContext);
  const { projectActions, catalog } = actions;

  const [renderer, _setRenderer] = useState(
    window.__threeRenderer ||
    new Three.WebGLRenderer({ preserveDrawingBuffer: true })
  );
  window.__threeRenderer = renderer;

  let { width, height } = props;

  width = width /2;
  height = height /2;

  

  useEffect(() => {
    let { state } = props;


    const scene3D = new Three.Scene();

    setTextureMultiplierHorizontal(1);
    setTextureMultiplierVertical(1);

    renderer.setClearColor(new Three.Color(SharedStyle.COLORS.white));
    renderer.setSize(width, height);

    const planData = parseData(state.scene, actions, catalog);



    addGroundPlane(scene3D);
    createSky("day", scene3D);


    scene3D.add(planData.plan);



    regenerateScene(scene3D, planData);



    let aspectRatio = width / height;

    const zoomFactor = 1.25;

    const cameras = {
      front: new Three.OrthographicCamera(
        -width / 2* zoomFactor,
        width / 2* zoomFactor,
        height / 2* zoomFactor,
        -height / 2* zoomFactor,
        1,
        50000000
      ),
      left: new Three.OrthographicCamera(
        -width / 2* zoomFactor,
        width / 2* zoomFactor,
        height / 2* zoomFactor,
        -height / 2* zoomFactor,
        1,
        50000000
      ),
      right: new Three.OrthographicCamera(
        -width / 2* zoomFactor,
        width / 2* zoomFactor,
        height / 2* zoomFactor,
        -height / 2* zoomFactor,
        1,
        50000000
      ),
      rear: new Three.OrthographicCamera(
        -width / 2* zoomFactor,
        width / 2* zoomFactor,
        height / 2* zoomFactor,
        -height / 2* zoomFactor,
        1,
        50000000
      ),
    };


    let cameraPositionX = -(planData.boundingBox.max.x - planData.boundingBox.min.x) / 2;
    let cameraPositionY = ((planData.boundingBox.max.y - planData.boundingBox.min.y) / 2) * 10;
    let cameraPositionYZ = ((planData.boundingBox.max.y - planData.boundingBox.min.y) / 2) * 2;
    let cameraPositionZ = (planData.boundingBox.max.z - planData.boundingBox.min.z) / 2;

    let zFactor = 4;
    let z = cameraPositionZ * zFactor;
    let x = cameraPositionX * zFactor;
    
    let cam = Math.max(Math.abs(z), Math.abs(x));

    cameras.front.position.set(0, 0, cam);
    cameras.left.position.set(-cam, 0, 0);
    cameras.right.position.set(cam, 0, 0);
    cameras.rear.position.set(0, 0, -cam);

















    const viewports = {
      front: { x: 0, y: 0, width: 0.5, height: 0.5 },
      left: { x: 0.5, y: 0, width: 0.5, height: 0.5 },
      right: { x: 0, y: 0.5, width: 0.5, height: 0.5 },
      rear: { x: 0.5, y: 0.5, width: 0.5, height: 0.5 },
    };

    let light = new Three.AmbientLight(0xafafaf);
    scene3D.add(light);

    let spotLight1 = new Three.SpotLight(SharedStyle.COLORS.white, 0.3);
    spotLight1.position.set(cameraPositionX, cameraPositionY * 10000, cameraPositionZ);
    scene3D.add(spotLight1);

    let toIntersect = [planData.plan];
    let mouse = new Three.Vector2();
    let raycaster = new Three.Raycaster();

    mouseDownEvent = (event) => {
      let x = (event.offsetX / props.width) * 2 - 1;
      let y = (-event.offsetY / props.height) * 2 + 1;
      Object.assign(lastMousePosition, { x: x, y: y });
    };
    mouseUpEvent = (event) => {
      event.preventDefault();
      mouse.x = (event.offsetX / props.width) * 2 - 1;
      mouse.y = -(event.offsetY / props.height) * 2 + 1;

      const selectedCamera = cameras.front;

      if (
        Math.abs(mouse.x - lastMousePosition.x) <= 0.02 &&
        Math.abs(mouse.y - lastMousePosition.y) <= 0.02
      ) {
        raycaster.setFromCamera(mouse, selectedCamera);
        let intersects = raycaster.intersectObjects(toIntersect, true);

        if (intersects.length > 0 && !isNaN(intersects[0].distance)) {
          intersects[0].object.interact && intersects[0].object.interact();
        } else {
          projectActions.unselectAll();
        }
      }
    };
    renderer.domElement.addEventListener("mousedown", mouseDownEvent);
    renderer.domElement.addEventListener("mouseup", mouseUpEvent);
    renderer.domElement.style.display = "block";

    canvasWrapper.current.appendChild(renderer.domElement);
    const orbitControllers = {};

    for (const cameraKey in cameras) {
      orbitControllers[cameraKey] = new OrbitControls(cameras[cameraKey], renderer.domElement);
      orbitControllers[cameraKey].enableRotate = false;
      orbitControllers[cameraKey].enableDamping = true;
      orbitControllers[cameraKey].enablePan = false;
      orbitControllers[cameraKey].enableZoom = false;
    }




    let render = () => {

      for (const cameraKey in cameras) {
        const camera = cameras[cameraKey];
        const orbitController = orbitControllers[cameraKey];
        const viewport = viewports[cameraKey];
        const { x, y, width, height } = viewport;


        //add timeout half second
        setTimeout(() => {
          addRoof(planData, scene3D);
        }, 250); 


        orbitController.update();
        renderer.setViewport(x * props.width, y * props.height, width * props.width, (height * props.height) * 1.25);
        renderer.setScissor(x * props.width, y * props.height, width * props.width, height * props.height);
        renderer.setScissorTest(true);

        camera.aspect = width / height;
        camera.updateMatrix();
        camera.updateMatrixWorld();

        for (let elemID in planData.sceneGraph.LODs) {
          planData.sceneGraph.LODs[elemID].update(camera);
        }

        renderer.render(scene3D, camera);
      }

      renderingID = requestAnimationFrame(render);
    };

    render();

    scene3DP = scene3D;
    planDataP = planData;

    return () => {
      cancelAnimationFrame(renderingID);

      renderer.domElement.removeEventListener("mousedown", mouseDownEvent);
      renderer.domElement.removeEventListener("mouseup", mouseUpEvent);
      renderer.setViewport(0, 0, props.width, props.height);
      renderer.setScissorTest(false);
      disposeScene(scene3DP);
      scene3DP.remove(planDataP.plan);
      scene3DP.remove(planDataP.grid);


      scene3DP = null;
      planDataP = null;
      cameraP = null;
      renderer.renderLists.dispose();
    };
  }, []);

  useEffect(() => {
    if (cameraP) {
      cameraP.aspect = props.width / props.height;
      cameraP.updateProjectionMatrix();
    }

    if (previousProps && props.state.scene !== previousProps.state.scene) {
      let changedValues = diff(previousProps.state.scene, props.state.scene);
      updateScene(
        planDataP,
        props.state.scene,
        previousProps.state.scene,
        changedValues.toJS(),
        actions,
        catalog
      );
    }

    renderer.setSize(props.width, props.height);


  }, [props]);

  // return <div ref={canvasWrapper} />;


  return (
    <div ref={canvasWrapper} style={{ marginLeft: 'auto' }}>
      {/* ... */}
    </div>
  );

};


export function sanitizeVector3(vector) {
  if (isNaN(vector.x)) vector.x = 0;
  if (isNaN(vector.y)) vector.y = 0;
  if (isNaN(vector.z)) vector.z = 0;
  return vector;
}





function createSky(timeOfDay, scene) {
  let materialArray = changeMaterialArray(timeOfDay);
  for (let i = 0; i < 6; i++) {
    materialArray[i].side = Three.BackSide;
  }

  // Create skybox geometry
  let skyboxGeo = new Three.BoxGeometry(1500, 1500, 1500);
  let skybox = new Three.Mesh(skyboxGeo, materialArray);

  //change position
  skybox.position.set(0, -100, 0);

  let oldSkybox = scene.getObjectByName("skybox");
  if (oldSkybox) {
    scene.remove(oldSkybox);
  }
  skybox.name = "skybox";
  scene.add(skybox);
}



function changeMaterialArray(part) {
  let texture_ft, texture_bk, texture_up, texture_dn, texture_rt, texture_lf;

  let imgResourcePath = "/textures/sky/";
  let materialArray = [];

  // const heightTexture = textureLoader.load('/textures/grass/stylized-grass1_metallic.png');

  texture_ft = new Three.TextureLoader().load(imgResourcePath + "TropicalSunnyDayFront.jpg"
  );
  texture_bk = new Three.TextureLoader().load(
    imgResourcePath + "TropicalSunnyDayBack.jpg"
  );
  texture_up = new Three.TextureLoader().load(
    imgResourcePath + "TropicalSunnyDayUp.jpg"
  );
  texture_dn = new Three.TextureLoader().load(
    imgResourcePath + "TropicalSunnyDayDown.jpg"
  );
  texture_rt = new Three.TextureLoader().load(
    imgResourcePath + "TropicalSunnyDayRight.jpg"
  );
  texture_lf = new Three.TextureLoader().load(
    imgResourcePath + "TropicalSunnyDayLeft.jpg"
  );

  materialArray.push(new Three.MeshBasicMaterial({ map: texture_ft }));
  materialArray.push(new Three.MeshBasicMaterial({ map: texture_bk }));
  materialArray.push(new Three.MeshBasicMaterial({ map: texture_up }));
  materialArray.push(new Three.MeshBasicMaterial({ map: texture_dn }));
  materialArray.push(new Three.MeshBasicMaterial({ map: texture_rt }));
  materialArray.push(new Three.MeshBasicMaterial({ map: texture_lf }));
  return materialArray;
}





// const BLADE_COUNT = 100000;
const BLADE_WIDTH = 2.5;
// const BLADE_WIDTH = 1;
const BLADE_HEIGHT = 12;
const BLADE_HEIGHT_VARIATION = 2.6;


// const imgResourcePath = "/textures/grass/";
const grassTexture = new Three.TextureLoader().load("/textures/grass/grass3.jpg");
grassTexture.wrapS = grassTexture.wrapT = THREE.RepeatWrapping;

// const cloudTexture = new Three.TextureLoader().load("/textures/grass/cloud2.jpg");
const cloudTexture = new Three.TextureLoader().load("/textures/grass/cloud.jpg");


cloudTexture.wrapS = cloudTexture.wrapT = THREE.RepeatWrapping;

const startTime = Date.now();
const timeUniform = { type: 'f', value: 0.0 };







// Grass Shader
const grassUniforms = {
  textures: { value: [grassTexture, cloudTexture] },
  uTime: timeUniform
};

const grassMaterial = new THREE.ShaderMaterial({
  uniforms: grassUniforms,
  // vertexShader: grassShader.vert,
  // fragmentShader: grassShader.frag,
  vertexShader: vertexShader,
  fragmentShader: fragmentShader,
  vertexColors: true,
  side: THREE.DoubleSide
});




function regenerateScene(scene3D, planData) {
  var sceneBoundingBox = new Three.Box3();

  scene3D.traverse(function (object) {
    if (object.isMesh) {
      object.geometry.computeBoundingBox();
      sceneBoundingBox.union(object.geometry.boundingBox);
    }
  });

  let dimA = planData.boundingBox.max.x - planData.boundingBox.min.x;
  let dimC = planData.boundingBox.max.z - planData.boundingBox.min.z;

  let ScenedimA = (sceneBoundingBox.max.x + sceneBoundingBox.min.x) / 2;
  let ScenedimC = (sceneBoundingBox.max.z + sceneBoundingBox.min.z) / 2;

  let maxDim = Math.max(dimA, dimC);

  PLANE_SIZE = maxDim * 2;

  generateField(scene3D);

  // addRoof(planData, scene3D);

}

function convertRange(val, oldMin, oldMax, newMin, newMax) {
  return (((val - oldMin) * (newMax - newMin)) / (oldMax - oldMin)) + newMin;
}

function generateField(scene) {
  const positions = [];
  const uvs = [];
  const indices = [];
  const colors = [];

  BLADE_COUNT = PLANE_SIZE * 4;

  for (let i = 0; i < BLADE_COUNT; i++) {
    const VERTEX_COUNT = 5;
    const surfaceMin = PLANE_SIZE / 2 * -1;
    const surfaceMax = PLANE_SIZE / 2;
    const radius = PLANE_SIZE / 2;

    const r = radius * Math.sqrt(Math.random());
    const theta = Math.random() * 2 * Math.PI;
    const x = r * Math.cos(theta);
    const y = r * Math.sin(theta);

    const pos = new THREE.Vector3(x, -(125), y);
    // groundCube.position.y = -(150+100);
    const uv = [convertRange(pos.x, surfaceMin, surfaceMax, 0, 1), convertRange(pos.z, surfaceMin, surfaceMax, 0, 1)];

    const blade = generateBlade(pos, i * VERTEX_COUNT, uv);
    blade.verts.forEach(vert => {
      positions.push(...vert.pos);
      uvs.push(...vert.uv);
      colors.push(...vert.color);
    });
    blade.indices.forEach(indice => indices.push(indice));
  }

  const geom = new Three.BufferGeometry();
  geom.addAttribute('position', new Three.BufferAttribute(new Float32Array(positions), 3));
  geom.addAttribute('uv', new Three.BufferAttribute(new Float32Array(uvs), 2));
  geom.addAttribute('color', new Three.BufferAttribute(new Float32Array(colors), 3));
  geom.setIndex(indices);

  geom.computeVertexNormals();
  geom.computeFaceNormals();

  const mesh = new Three.Mesh(geom, grassMaterial);
  scene.add(mesh);
}

function generateBlade(center, vArrOffset, uv) {
  const MID_WIDTH = BLADE_WIDTH * 0.5;
  // const TIP_OFFSET = 0.1;
  const TIP_OFFSET = 15;
  const height = BLADE_HEIGHT + (Math.random() * BLADE_HEIGHT_VARIATION);

  const yaw = Math.random() * Math.PI * 2;
  const yawUnitVec = new THREE.Vector3(Math.sin(yaw), 0, -Math.cos(yaw));
  const tipBend = Math.random() * Math.PI * 2;
  const tipBendUnitVec = new THREE.Vector3(Math.sin(tipBend), 0, -Math.cos(tipBend));

  // Find the Bottom Left, Bottom Right, Top Left, Top right, Top Center vertex positions
  const bl = new THREE.Vector3().addVectors(center, new THREE.Vector3().copy(yawUnitVec).multiplyScalar((BLADE_WIDTH / 2) * 1));
  const br = new THREE.Vector3().addVectors(center, new THREE.Vector3().copy(yawUnitVec).multiplyScalar((BLADE_WIDTH / 2) * -1));
  const tl = new THREE.Vector3().addVectors(center, new THREE.Vector3().copy(yawUnitVec).multiplyScalar((MID_WIDTH / 2) * 1));
  const tr = new THREE.Vector3().addVectors(center, new THREE.Vector3().copy(yawUnitVec).multiplyScalar((MID_WIDTH / 2) * -1));
  const tc = new THREE.Vector3().addVectors(center, new THREE.Vector3().copy(tipBendUnitVec).multiplyScalar(TIP_OFFSET));

  tl.y += height / 2;
  tr.y += height / 2;
  tc.y += height;

  // Vertex Colors
  const black = [0, 0, 0];
  const gray = [0.5, 0.5, 0.5];
  const white = [1.0, 1.0, 1.0];
  // const red = [1.0, 0.0, 0.0];
  // const gray = [1.0, 0.0, 0.0];
  // const black = [1.0, 0.0, 0.0];
  // const white = [1.0, 0.0, 0.0];


  const verts = [
    { pos: bl.toArray(), uv: uv, color: black },
    { pos: br.toArray(), uv: uv, color: black },
    { pos: tr.toArray(), uv: uv, color: gray },
    { pos: tl.toArray(), uv: uv, color: gray },
    { pos: tc.toArray(), uv: uv, color: white }
  ];

  const indices = [
    vArrOffset,
    vArrOffset + 1,
    vArrOffset + 2,
    vArrOffset + 2,
    vArrOffset + 4,
    vArrOffset + 3,
    vArrOffset + 3,
    vArrOffset,
    vArrOffset + 2
  ];

  return { verts, indices };
}








function addGroundPlane(scene) {
  const groundColor = new THREE.Color(160 / 255, 180 / 255, 123 / 255);

  const groundGeometry = new Three.BoxGeometry(50, 2500, 4000);
  const groundMaterial = new THREE.MeshBasicMaterial({ color: groundColor });

  const groundGeometry2 = new Three.BoxGeometry(4000, 2500, 50);

  const groundCube = new Three.Mesh(groundGeometry, groundMaterial);
  const groundCube2 = new Three.Mesh(groundGeometry2, groundMaterial);
  groundCube.position.y = -(150 + 100 + 1125);
  groundCube2.position.y = -(150 + 100 + 1125);

  scene.add(groundCube);
  scene.add(groundCube2);
}



function addRoof(planData, scene3D) {
  const overallBoundingBox = planData.boundingBox;


  if(planData.plan.children.length == 0){
    if (currentRoof) {
      scene3D.remove(currentRoof);
    }
  }

  else{

  let dimA = overallBoundingBox.max.x - overallBoundingBox.min.x;
  let dimC = overallBoundingBox.max.z - overallBoundingBox.min.z;


  let roofPositionX = (overallBoundingBox.max.x + overallBoundingBox.min.x) / 2;
  let roofPositionZ = (overallBoundingBox.max.z + overallBoundingBox.min.z) / 2;
  let roofPositionY = 125;

  let xOffset = (-leftOverhang/2) + (rightOverhang/2)+0;
  let yOffset = (-RearOverhang/2) + (FrontOverhang/2);
  

  setOverallWidth(dimA);
  setOverallDepth(dimC);


  dimA = dimA + leftOverhang + rightOverhang;
  dimC = dimC + FrontOverhang + RearOverhang;



  let roofGeometry = new Three.BoxGeometry(dimA, dimC, 20);

  const roofColor = new THREE.Color(56 / 255, 62 / 255, 66 / 255);
  const roofMaterial = new THREE.MeshBasicMaterial({ color: roofColor });
  const roofCube = new Three.Mesh(roofGeometry, roofMaterial);


  roofCube.rotation.x -= Math.PI / 2;
  roofCube.position.set(roofPositionX+xOffset, roofPositionY, roofPositionZ+yOffset);
  roofCube.name = 'roof';

  // Remove the current roof if it exists
  if (currentRoof) {
    scene3D.remove(currentRoof);
  }

  // Add the new roof to the scene
  scene3D.add(roofCube);

  // Set the current roof to the new roof
  currentRoof = roofCube;
}
}




Scene3DViewer.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default Scene3DViewer;


