import React from 'react';
import * as Three from 'three';
// import {loadObjWithMaterial} from '../../utils/load-obj';
import {loadObjWithMaterial, loadObjWithMaterial2} from '../../utils/load-obj';
import path from 'path';

let cached3DWindow = null;

export default {
  name: "window-horizontal-fixed",
  prototype: "holes",

  info: {
    title: "Fixed Horizontal Window",
    tag: ['window'],
    description: "Window",
    image: require('./window.png')
  },

  properties: {
    width: {
      label: "Width",
      type: "length-measure",
      defaultValue: {
        length: 150
      }
    },
    height: {
      label: "Height",
      type: "length-measure",
      hide: true,
      defaultValue: {
        length: 60
      }
    },
    altitude: {
      label: "Altitude",
      type: "length-measure",
      hide: true,
      defaultValue: {
        length: 155
      }
    },
    thickness: {
      label: "Thickness",
      type: "length-measure",
      hide: true,
      defaultValue: {
        length: 20
      }
    }
  },

  render2D: function (element, layer, scene) {
    const STYLE_HOLE_BASE = {stroke: "#000", strokeWidth: "3px", fill: "#84e1ce"};
    const STYLE_HOLE_SELECTED = {stroke: "#0096fd", strokeWidth: "3px", fill: "#84e1ce", cursor: "move"};
  
    // Increased epsilon for the height of the hole
    let epsilon = 10;
  
    // Get the width of the hole from the element's properties
    let holeWidth = element.properties.get('width').get('length');
  
    // Define the path for the hole's outline
    let holePath = `M${0} ${ -epsilon}  L${holeWidth} ${-epsilon}  L${holeWidth} ${epsilon}  L${0} ${epsilon}  z`;
  
    // Choose the style based on whether the element is selected or not
    let holeStyle = element.selected ? STYLE_HOLE_SELECTED : STYLE_HOLE_BASE;
  
    // The line should be at y=0, going from x=0 to x=holeWidth
    let linePath = `M0,0 L${holeWidth},0`;
  
    // Use the same stroke style for the line as the hole border for consistency
    let lineStyle = {stroke: holeStyle.stroke, strokeWidth: holeStyle.strokeWidth};
  
    // The group element is translated to center the hole on the element's position
    return (
      <g transform={`translate(${-holeWidth / 2}, 0)`}>
        <path key="hole" d={holePath} style={holeStyle}/> {/* Hole path */}
        <path key="centerLine" d={linePath} style={lineStyle}/> {/* Center line */}
      </g>
    );
  }
,  


  render3D: function (element, layer, scene) {
    let w = element.properties.getIn(["width", "length"]);
    let h = element.properties.getIn(["height", "length"]);
    let WallThickeness = element.properties.getIn(["thickness", "length"]);
    let d = 7;

    let frame = new Three.Group();

    let materialFrame = new Three.MeshBasicMaterial({
      color: 0x383e42,
    });
    let materialGlass = new Three.MeshBasicMaterial({
      color: 0x6e95e1,
    });    

    function createOuterFrame(width, height, depth, position) {
      let geometry = new Three.BoxGeometry(width, height, depth);
      let mesh = new Three.Mesh(geometry, materialFrame);
      mesh.position.set(position.x, position.y, position.z);

      frame.add(mesh);
    }
    CreateOuterFrameBars(w, d, WallThickeness, h);

    function CreateOuterFrameBars(w1, d1, WallThickeness1, h1) {
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: h1 / 2 - d1 / 2, z: 0 });
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: -h1 / 2 + d1 / 2, z: 0 });
      createOuterFrame(d1, h1 - 2 * d1, WallThickeness1, {x: -w1 / 2 + d1 / 2,y: 0,z: 0,});
      createOuterFrame(d1, h1 - 2 * d1, WallThickeness1, {x: w1 / 2 - d1 / 2,y: 0,z: 0,});


      //inner top and bottom
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: (h1 / 2 - d1 / 2)-d1, z: 0 });
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: (-h1 / 2 + d1 / 2)-d1, z: 0 });

    }

    function CreateInnerFrameBars(w1, d1, WallThickeness1, h1, positionX) {


//left and right bars
      createOuterFrame(d1, h1, WallThickeness1, {x: -positionX,y: 0-(d1/2),z: 0,});
      createOuterFrame(d1, h1, WallThickeness1, {x: positionX,y: 0-(d1/2),z: 0,});

      

    }

    
    function createFrameGlass(width, height, depth) {
      let geometryGlass = new Three.BoxGeometry(width, height, depth / 5);
      let meshGlass = new Three.Mesh(geometryGlass, materialGlass);
      frame.add(meshGlass);
    }

    createFrameGlass(w, h, WallThickeness / 5);

    
    function createInnerFrames(d1, WallThickeness1, h1, totalWidth, panelCount) {

      let panelWidth = (totalWidth-(d*2)) / panelCount;
      for (let i = 0; i < panelCount; i++) {

        let positionX = -totalWidth / 2 + panelWidth / 2 + i * panelWidth;

        CreateInnerFrameBars(panelWidth, d1, WallThickeness1, h1-(d1*2) , d1+positionX+(panelWidth/2));

      }
    }

    createInnerFrames(d, WallThickeness, h, w, 1); 



    return Promise.resolve(frame);
  },












};
