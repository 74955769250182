import React, { useState } from "react";
import PropTypes from 'prop-types';
import * as SharedStyle from '../../styles/shared-style';
import RoofIcon from "./icons/roof.svg";
// import { ReactComponent as RoofIcon } from "./icons/roof.svg";

const DropdownItem = ({ label, options, onSelect }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [styleState, setStyleState] = useState({ active: false });

  const toggleOption = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const toggleDropdownVisibility = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  let color = styleState.active ? SharedStyle.SECONDARY_COLOR.icon : SharedStyle.PRIMARY_COLOR.icon;
  let stroke = styleState.active ? SharedStyle.SECONDARY_COLOR.icon : SharedStyle.PRIMARY_COLOR.icon;
  let fill = styleState.active ? SharedStyle.SECONDARY_COLOR.icon : SharedStyle.PRIMARY_COLOR.icon;

  const styles = {
    dropdownItem: {
      display: 'flex',
      alignItems: 'left',
      justifyContent: 'left',
      marginTop: '5px',
      marginLeft: '27px',
      marginRight: '8px',
      marginBottom: '10px',
      cursor: 'pointer',
      fontSize: '12px',
      color: SharedStyle.COLORS.black,
    },
    dropdownLabel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingLeft: '0px',
      width: '200px',
      color: color,
      borderRadius: '10px',
    },
    icon: {
      marginLeft: '0px',
      marginRight: '16px',
      paddingRight: '00px',
      paddingLeft: '0px',  
      alignSelf: 'left', 
      color: SharedStyle.MATERIAL_COLORS["500"].indigo,
      stroke: SharedStyle.MATERIAL_COLORS["500"].indigo,
    },
    dropdownOptions: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px',
      backgroundColor: SharedStyle.COLORS.white,
      borderRadius: '10px',
      position: 'absolute',
      zIndex: '999',
      width: '200px',
      left: '100%',
      top: '35%',
    },
    dropdownOption: {
      marginBottom: '5px',
    },
    applyButton: {
      backgroundColor: fill,
      border: 'none',
      color: SharedStyle.COLORS.white,
      backgroundColor: SharedStyle.MATERIAL_COLORS["500"].indigo,
      padding: '5px 10px',
      textAlign: 'center',
      textDecoration: 'none',
      display: 'inline-block',
      margin: '4px 2px',
      cursor: 'pointer',
      borderRadius: '10px',
    }


    

  };

  return (
    <div style={styles.dropdownItem}
         onMouseOver={() => setStyleState({ active: true })}
         onMouseOut={() => setStyleState({ active: false })}>
      <div style={styles.dropdownLabel} onClick={toggleDropdownVisibility}>
      <RoofIcon style = {styles.icon}/> {/* Using SVG as a component */}
        {label}
      </div>
      {isDropdownVisible && (
        <div style={styles.dropdownOptions}>
          {options.map((option) => (
            <label key={option} style={styles.dropdownOption}>
              <input
                type="checkbox"
                value={option}
                checked={selectedOptions.includes(option)}
                onChange={() => toggleOption(option)}
              />
              {option}
            </label>
          ))}
          <button style={styles.applyButton} onClick={() => onSelect(selectedOptions)}>Apply</button>
        </div>
      )}
    </div>
  );
};

DropdownItem.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default DropdownItem;
