export let textureMultiplierHorizontal = 1;
export let textureMultiplierVertical = 1;


export function setTextureMultiplierHorizontal(value) {
  textureMultiplierHorizontal = value;
}

export function setTextureMultiplierVertical(value) {
  textureMultiplierVertical = value;
}


