import React, { useState, useContext } from "react";
import {
  MODE_3D_VIEW,
  MODE_3D_ELEVATIONS,
  MODE_IDLE,
} from "../../utils/constants";
import {
  selectTool3DView,
  selectToolElevations,
} from "../../actions/viewer3d-actions";
import { useDispatch } from "react-redux";
import html2canvas from "html2canvas";
import {
  getOverallFloorArea,
  getOverallDepth,
  getOverallWidth,
  validateBuildingForExport,
} from "../../components/viewer3d/buildingDataExport";
import ReactPlannerContext from "../../utils/react-planner-context";
import { setLoading, getLoading } from "./LoadingOverlay"; 

let folderName = "";
let buildingWidth = 0;
let buildingDepth = 0;
let buildingFloorArea = 0;
let name = "";
let postcode = "";
let phone = "";
let email = "";
let supplyInstall = "Supply & Install";
let comments = "";

const ContactPage = ({ state }) => {
  const [formData, setFormData] = useState({
    name: "",
    postcode: "",
    phone: "",
    email: "",
    supplyInstall: "Supply & Install",
    comments: "",
  });

  // const { projectActions, viewer3DActions, translator, Cat, toggleLoading } =
  //   useContext(ReactPlannerContext);

    const { projectActions, viewer3DActions, translator, Cat } =
      useContext(ReactPlannerContext);

  // const [isLoading, setIsLoading] = useState(getLoading());
  
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
    
    console.log("Form data submitted:", formData);

    const formattedJSON = JSON.stringify(state.get("scene").toJS(), null, 2);

    const screenshotElevations = await switchModeAndViewScreenshot(
      dispatch,
      selectToolElevations
    );
    const screenshot3D = await switchModeAndViewScreenshot(
      dispatch,
      selectTool3DView
    );
    const screenshotPlan = await switchModeAndViewScreenshotPlan();

    let validation = validateBuildingForExport();

    if (validation === true) {
      buildingWidth = getOverallWidth();
      buildingDepth = getOverallDepth();
      buildingFloorArea = getOverallFloorArea();

      name = formData.name;
      postcode = formData.postcode;
      phone = formData.phone;
      email = formData.email;
      comments = formData.comments;

      if (formData.supplyInstall === "Supply Only") {
        supplyInstall = "Supply Only";
      }

      createProjectFolderPHP(
        formattedJSON,
        screenshotElevations,
        screenshot3D,
        screenshotPlan
      );

      // sendEmailPHP();

      alert(
        "Your request has been packaged together and will be sent to different manufacturers shortly. Thank you!"
      );
      setLoading(false);
    } else {
      alert(
        "It looks like you haven't designed a building yet. Please go to the floor plan tab and start designing."
      );

      setLoading(false); 
    }

    console.log("Form data submitted:", formData);
  };

  async function switchModeAndViewScreenshot(dispatch, actionCreator) {
    dispatch(actionCreator());
    await waitForCanvasRender();

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(saveScreenshot());
      }, 2000);
    });
  }
  async function switchModeAndViewScreenshotPlan() {
    // dispatch(actionCreator());

    projectActions.setMode(MODE_IDLE);

    await waitForCanvasRender();

    const blob = await new Promise((resolve) => {
      setTimeout(() => {
        resolve(saveScreenshotPlan());
      }, 2000);
    });
    console.log("Screenshot Plan Blob:", blob); // Add this line
    return blob;
  }
  function waitForCanvasRender() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }

  function saveScreenshot() {
    const canvas = document.querySelector("canvas");
    if (!canvas) {
      console.error("No canvas found for screenshot");
      return null;
    }

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/png");
    });
  }

  function saveScreenshotPlan() {
    return new Promise((resolve, reject) => {
      const element = document.getElementById("floorplanArea");
      if (!element) {
        console.error("Element with specified ID not found");
        reject("Element not found");
        return;
      }

      html2canvas(element)
        .then((canvas) => {
          // The canvas now contains the screenshot of the specified element
          canvas.toBlob((blob) => {
            resolve(blob);
          }, "image/png");
        })
        .catch((error) => {
          console.error("Error taking screenshot:", error);
          reject("Error taking screenshot");
        });
    });
  }

  function createProjectFolderPHP(
    formattedJSON,
    screenshotBlob1,
    screenshotBlob2,
    screenshotBlob3
  ) {
    const phpScriptUrl = `https://gardenroomdesigner.co.uk/projects/CreateAndUploadProject.php`;
    const formData = new FormData();

    if (!(screenshotBlob1 instanceof Blob))
      console.error("screenshotBlob1 is not a Blob:", screenshotBlob1);
    if (!(screenshotBlob2 instanceof Blob))
      console.error("screenshotBlob2 is not a Blob:", screenshotBlob2);
    if (!(screenshotBlob3 instanceof Blob))
      console.error("screenshotBlob3 is not a Blob:", screenshotBlob3);

    formData.append("formattedJSON", formattedJSON);
    formData.append(
      "screenshotElevations",
      screenshotBlob1,
      `screenshot-Elevations-${new Date().toISOString()}.png`
    );
    formData.append(
      "screenshot3D",
      screenshotBlob2,
      `screenshot-3D-${new Date().toISOString()}.png`
    );
    formData.append(
      "screenshotPlan",
      screenshotBlob3,
      `screenshot-Plan-${new Date().toISOString()}.png`
    );

    fetch(phpScriptUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((responseText) => {
        console.log("PHP Script Response:", responseText);
        folderName = responseText;

        // setTimeout(() => {
        // sendEmailPHP();
        // }, 5000);

        sendEmailPHP();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function sendEmailPHP() {
    const phpScriptUrl =
      "https://gardenroomdesigner.co.uk/projects/SendEmail.php";

    const formData = new FormData();
    formData.append("folderName", folderName + "");
    formData.append("buildingWidth", buildingWidth + "cm");
    formData.append("buildingDepth", buildingDepth + "cm");
    formData.append("buildingFloorArea", buildingFloorArea + "m2");
    formData.append("name", name);
    formData.append("postcode", postcode);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("supplyInstall", supplyInstall);
    formData.append("comments", comments);

    // Make a POST request to the PHP script
    fetch(phpScriptUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          // Email sent successfully
          console.log("Email sent successfully");
        } else {
          // Email sending failed
          console.error("Email sending failed");
        }
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSupplyInstallChange = (e) => {
    const supplyInstallValue = e.target.value;
    setFormData({
      ...formData,
      supplyInstall: supplyInstallValue,
    });
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    marginLeft: "15vw",
    backgroundColor: "#f2f2f2",
  };
  const bodyStyle = {
    margin: 0,
    padding: 0,
    backgroundColor: "#f2f2f2",
  };
  const formRowStyle = {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
    width: "100%", // Adjust as needed
  };

  const labelStyle = {
    fontWeight: "bold",
    fontSize: "10px",
    marginBottom: "5px",
  };

  const inputStyle = {
    padding: "7px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    width: "300px", // Consistent width for all inputs
    fontSize: "10px",
  };

  const radioButtonStyle = {
    fontSize: "14px",
    marginBottom: "5px", // Consistent spacing for radio buttons
  };

  const buttonStyle = {
    backgroundColor: "#007bff",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    fontSize: "10px",
    cursor: "pointer",
    width: "300px", // Same width as input fields for alignment
  };

  const headerStyle = {
    fontSize: "14px",
    marginLeft: "15vw",
    marginBottom: "20px", // Added spacing above the form
  };
  const radioButton = {
    fontSize: "14px",
  };

  const buttonHoverStyle = {
    backgroundColor: "#0056b3",
  };

  return (
    <div>

{/* {isLoading && <LoadingOverlay />}  */}


      <body style={bodyStyle}>
        <h2 style={headerStyle}>Request Quotes From Installers</h2>
        <form onSubmit={handleSubmit} style={containerStyle}>
          <div style={formRowStyle}>
            <label htmlFor="name" style={labelStyle}>
              Name:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              style={inputStyle}
            />
          </div>
          <div style={formRowStyle}>
            <label htmlFor="postcode" style={labelStyle}>
              Postcode:
            </label>
            <input
              type="text"
              id="postcode"
              name="postcode"
              value={formData.postcode}
              onChange={handleChange}
              required
              style={inputStyle}
            />
          </div>

          <div style={formRowStyle}>
            <label htmlFor="phone" style={labelStyle}>
              Phone:
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              style={inputStyle}
            />
          </div>

          <div style={formRowStyle}>
            <label htmlFor="email" style={labelStyle}>
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              style={inputStyle}
            />
          </div>

          <div style={formRowStyle}>
            <label style={labelStyle}>Supply & Install:</label>
            <div>
              <label style={radioButtonStyle}>
                <input
                  type="radio"
                  name="supplyInstall"
                  value="Supply and Install"
                  checked={formData.supplyInstall === "Supply and Install"}
                  onChange={handleSupplyInstallChange}
                />
                <span style={{ fontSize: "10px" }}>Supply & Install</span>
              </label>
            </div>
            <div>
              <label style={radioButtonStyle}>
                <input
                  type="radio"
                  name="supplyInstall"
                  value="Supply Only"
                  checked={formData.supplyInstall === "Supply Only"}
                  onChange={handleSupplyInstallChange}
                />
                <span style={{ fontSize: "10px" }}>Supply only</span>
              </label>
            </div>
          </div>

          <div style={formRowStyle}>
          <label htmlFor="comments" style={labelStyle}>
            Additional Comments:
          </label>
          <textarea
            id="comments"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            style={inputStyle}
          />
        </div>


          <button type="submit" style={{ ...buttonStyle, ...buttonHoverStyle }}>
            Submit
          </button>



          <div style={formRowStyle}>
          Your project details will be shared with various garden room manufacturers, and they will provide you with quotes directly. We do not retain any of your personal data.
        </div>

        </form>
      </body>
    </div>
  );


};

export default ContactPage;
