import React, { memo, useContext, useState } from "react";
import PropTypes from "prop-types";
import ReactPlannerContext from "../../utils/react-planner-context";
import NewSVG from "./icons/new.svg";
import CatalogSVG from "./icons/catalog.svg";
import DoorSVG from "./icons/door.svg";
import WindowSVG from "./icons/window.svg";
import ExternalWallSVG from "./icons/wallExternal.svg";
import Icon2DSVG from "./icons/2D.svg";
import Icon3DSVG from "./icons/3D.svg";
import UndoSVG from "./icons/undo.svg";
import RoofSVG from "./icons/roof.svg";
import CladdingSVG from "./icons/cladding.svg";
import RedoSVG from "./icons/redo.svg";
import SettingsSVG from "./icons/settings.svg";
import { selectItem } from "../../actions/items-actions"; // adjust the path according to your file structure
import ToolbarButton from "./toolbar-button";
import ToolbarSaveButton from "./toolbar-save-button";
import ToolbarLoadButton from "./toolbar-load-button";
import { resetAllOverhangs } from "../viewer3d/roofConfig.js";

import DropdownItem from "./DropdownItem";
import DropdownItemCladding from "./DropdownItemCladding";
import {
  setLeftOverhang,
  setRightOverhang,
  setFrontOverhang,
  setRearOverhang,
} from "../../components/viewer3d/roofConfig";

import claddingTextures, {
  setSelectedCladdingFromMenu,
} from "../../catalog/factories/claddingConfigC";

export let activeCatalogButton = "Wall";

import If from "../../utils/react-if";
import {
  MODE_IDLE,
  MODE_3D_VIEW,
  MODE_3D_ELEVATIONS,
  MODE_3D_FIRST_PERSON,
  MODE_VIEWING_CATALOG,
  MODE_VIEWING_CATALOG_WALLS,
  MODE_VIEWING_CATALOG_WINDOWS,
  MODE_VIEWING_CATALOG_DOORS,
  MODE_GET_QUOTES,
  SELECT_TOOL_DRAWING_ITEM,
  MODE_CONFIGURING_PROJECT,
  PROJECT_ACTIONS,
} from "../../utils/constants";
import * as SharedStyle from "../../styles/shared-style";
import { reset } from "react-svg-pan-zoom";

const ASIDE_STYLE = {
  left: 0,
  right: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  fontSize: "10px",
  backgroundColor: SharedStyle.PRIMARY_COLOR.main,
  border: "2px solid",
  borderRadius: "30px",
  borderColor: SharedStyle.PRIMARY_COLOR.alt,
  padding: "10px",
  height: "90vh",
  marginLeft: "-30px",
  // minWidth: "10%",
  // width: "100%",
  width: "calc(15% + 30px)",
  minWidth: "270px",
  marginTop: "15px",
  zIndex: 99999,
};

const sortButtonsCb = (a, b) => {
  if (a.index === undefined || a.index === null) {
    a.index = Number.MAX_SAFE_INTEGER;
  }

  if (b.index === undefined || b.index === null) {
    b.index = Number.MAX_SAFE_INTEGER;
  }

  return a.index - b.index;
};

const mapButtonsCb = (el, ind) => {
  return (
    <If key={ind} condition={el.condition} style={{ position: "relative" }}>
      {el.dom}
    </If>
  );
};

const Toolbar = ({ state, toolbarButtons, allowProjectFileSupport }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedCladding, setSelectedCladding] = useState("cedarVertical");

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const { projectActions, viewer3DActions, translator, Cat } =
    useContext(ReactPlannerContext);

  let mode = state.get("mode");

  let sorter = [
    {
      index: 0,
      condition: allowProjectFileSupport,
      dom: (
        <ToolbarButton
          active={false}
          tooltip={translator.t("New project")}
          label="New"
          onClick={(event) =>
            confirm(translator.t("Would you want to start a new Project?"))
              ? (projectActions.newProject(), resetAllOverhangs())
              : null
          }
        >
          <NewSVG />
          {/* New */}
        </ToolbarButton>
      ),
    },
    {
      index: 1,
      condition: allowProjectFileSupport,
      dom: <ToolbarSaveButton label="Save" New state={state} />,
    },
    {
      index: 2,
      condition: allowProjectFileSupport,
      dom: <ToolbarLoadButton label="Load" state={state} />,
    },

    {
      index: 6,
      condition: true,
      dom: (
        <ToolbarButton
          active={[MODE_VIEWING_CATALOG_WALLS].includes(mode)}
          tooltip={translator.t("New Wall")}
          label="Walls"
          onClick={(event) => {
            activeCatalogButton = "Wall";
            projectActions.setMode(MODE_IDLE);
            projectActions.unselectAll();

            //timeout for 250ms
            projectActions.openCatalogWalls();
          }}
        >
          <ExternalWallSVG />
          {/* Walls */}
        </ToolbarButton>
      ),
    },

    {
      index: 7,
      condition: true,
      dom: (
        <ToolbarButton
          active={[MODE_VIEWING_CATALOG_DOORS].includes(mode)}
          tooltip={translator.t("New Door Instance")}
          label="Doors"
          onClick={(event) => {
            activeCatalogButton = "Door";
            projectActions.setMode(MODE_IDLE);
            projectActions.unselectAll();

            projectActions.openCatalogDoors();
          }}
        >
          <DoorSVG />
          {/* Doors */}
        </ToolbarButton>
      ),
    },

    {
      index: 8,
      condition: true,
      dom: (
        <ToolbarButton
          active={[MODE_VIEWING_CATALOG_WINDOWS].includes(mode)}
          tooltip={translator.t("New Window Instance")}
          label="Windows"
          onClick={(event) => {
            activeCatalogButton = "Window";
            projectActions.setMode(MODE_IDLE);
            projectActions.unselectAll();

            projectActions.openCatalogWindows();
          }}
        >
          <WindowSVG />
          {/* Windows */}
        </ToolbarButton>
      ),
    },

    {
      index: 3,
      condition: true,
      dom: (
        <ToolbarButton
          active={[MODE_IDLE].includes(mode)}
          tooltip={translator.t("2D Floor Planner")}
          label="Floor Plan"
          onClick={(event) => projectActions.setMode(MODE_IDLE)}
        >
          <Icon2DSVG />
          {/* 2D */}
        </ToolbarButton>
      ),
    },

    {
      index: 8,
      condition: true,
      dom: (
        <DropdownItem
          label="Roof Settings"
          options={[
            "Front Overhang",
            "Left Overhang",
            "Right Overhang",
            "Rear Overhang",
          ]}
          onSelect={(selectedOptions) => {
            let newFrontOverhang = 0;
            let newLeftOverhang = 0;
            let newRightOverhang = 0;
            let newRearOverhang = 0;

            selectedOptions.forEach((option) => {
              switch (option) {
                case "Front Overhang":
                  newFrontOverhang = 50;
                  break;
                case "Left Overhang":
                  newLeftOverhang = 50;
                  break;
                case "Right Overhang":
                  newRightOverhang = 50;
                  break;
                case "Rear Overhang":
                  newRearOverhang = 50;
                  break;
                default:
                  break;
              }
            });

            // Update values in roofConfig.js
            setFrontOverhang(newFrontOverhang);
            setLeftOverhang(newLeftOverhang);
            setRightOverhang(newRightOverhang);
            setRearOverhang(newRearOverhang);

            closeDropdown();
          }}
        >
          <RoofSVG />
        </DropdownItem>
      ),
    },
    {
      index: 9,
      condition: true,
      dom: (
        <DropdownItemCladding
          label="Cladding Settings"
          options={Object.values(claddingTextures).map((config) => config.name)}
          onSelect={(selectedOptionName) => {
            const selectedKey = Object.keys(claddingTextures).find(
              (key) => claddingTextures[key].name === selectedOptionName
            );
            setSelectedCladdingFromMenu(selectedOptionName);
            closeDropdown();

            //if mode_3d_View the do selectTool3DView
            if (mode === MODE_3D_VIEW) {
              // viewer3DActions.selectToolElevations();

              projectActions.openCatalogWalls();

              setTimeout(() => {
                viewer3DActions.selectTool3DView();
              }, 0.00000000000001);
            }

            //if mode_3d_Elevations the do selectToolElevations
            if (mode === MODE_3D_ELEVATIONS) {
              // viewer3DActions.selectTool3DView();

              projectActions.openCatalogWalls();

              setTimeout(() => {
                viewer3DActions.selectToolElevations();
              }, 0.00000000000001);
            }
          }}
        >
          <CladdingSVG />
        </DropdownItemCladding>
      ),
    },

    {
      index: 9,
      condition: true,
      dom: (
        <ToolbarButton
          active={[MODE_3D_VIEW].includes(mode)}
          tooltip={translator.t("3D Viewer")}
          label="3D View"
          onClick={(event) => viewer3DActions.selectTool3DView()}
        >
          <Icon3DSVG />
          {/* 3D */}
        </ToolbarButton>
      ),
    },
    {
      index: 10,
      condition: true,
      dom: (
        <ToolbarButton
          active={[MODE_3D_ELEVATIONS].includes(mode)}
          tooltip={translator.t("See elevations")}
          label="Elevations"
          onClick={(event) => viewer3DActions.selectToolElevations()}
        >
          <Icon3DSVG />
        </ToolbarButton>
      ),
    },

    {
      index: 11,
      condition: true,
      dom: (
        <ToolbarButton
          active={false}
          tooltip={translator.t("Undo (CTRL-Z)")}
          label="Undo"
          onClick={(event) => projectActions.undo()}
        >
          <UndoSVG />
          {/* Undo */}
        </ToolbarButton>
      ),
    },
    {
      index: 12,
      condition: true,
      dom: (
        <ToolbarButton
          active={false}
          tooltip={translator.t("Redo (CTRL-Y)")}
          label="Redo"
          onClick={(event) => projectActions.redo()}
        >
          <RedoSVG />
          {/* Redo */}
        </ToolbarButton>
      ),
    },

    {
      index: 13,
      condition: true,
      dom: (
        <ToolbarButton
          active={[MODE_3D_VIEW].includes(mode)}
          tooltip={translator.t("Send to manufacturers")}
          label="Request Quotes"
          state={state}
          onClick={(event) => projectActions.getQuotes()}
        >
          <Icon3DSVG />
        </ToolbarButton>
      ),
    },
    // dom: <ToolbarSaveButton label="Save" New state={state} />,
  ];

  sorter = sorter.concat(
    toolbarButtons.map((Component, key) => {
      return Component.prototype //if is a react component
        ? {
            condition: true,
            dom: React.createElement(Component, { mode, state, key }),
          }
        : {
            //else is a sortable toolbar button
            index: Component.index,
            condition: Component.condition,
            dom: React.createElement(Component.dom, { mode, state, key }),
          };
    })
  );

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "95vh",
        // width: "110px",
        // width: "10%" + "30px",
        width: "calc(10% + 30px)",
        minWidth: "110px",
        // marginLeft: "1px",
        zIndex: 9999,
      }}
    >
      <aside style={ASIDE_STYLE} className="toolbar">
        {sorter.sort(sortButtonsCb).map(mapButtonsCb)}
      </aside>
    </div>
  );
};

Toolbar.propTypes = {
  state: PropTypes.object.isRequired,
  allowProjectFileSupport: PropTypes.bool.isRequired,
  toolbarButtons: PropTypes.array,
};

export default memo(Toolbar, (prevProps, nextProps) => {
  return prevProps.state.hashCode() === nextProps.state.hashCode();
});
