import React from "react";
import * as Three from "three";
// import { loadObjWithMaterial } from "../../utils/load-obj";
// import {
//   BoxGeometry,
//   EdgesGeometry,
//   LineBasicMaterial,
//   LineSegments,
//   MeshBasicMaterial,
//   Mesh,
//   BoxHelper,
// } from "three";
// // import * as SharedStyle from "../../styles/shared-style";
// import { ReactPlannerSharedStyle } from "react-planner";
// let cached3DDoor = null;

export default {
  name: "door-double",
  prototype: "holes",

  info: {
    title: "Double Door",
    tag: ["door"],
    description: "Door",
    image: require("./door.png"),
  },

  properties: {
    width: {
      label: "Width",
      type: "length-measure",
      defaultValue: {
        length: 240,
      },
    },
    height: {
      label: "Height",
      type: "length-measure",
      hide: true,
      defaultValue: {
        length: 200,
      },
    },
    altitude: {
      label: "Altitude",
      type: "length-measure",
      hide: true,
      defaultValue: {
        length: 15,
      },
    },
    thickness: {
      label: "Thickness",
      type: "length-measure",
      hide: true,
      defaultValue: {
        length: 20,
      },
    },
    flip_horizontal: {
      label: "Horizontal Flip",
      type: "checkbox",
      defaultValue: false,
      values: {
        none: false,
        yes: true,
      },
    },
    flip_vertical: {
      label: "Vertical Flip",
      type: "checkbox",
      defaultValue: false,
      values: {
        none: false,
        yes: true,
      },
    },
  },

  render2D: function (element, layer, scene) {
    const STYLE_HOLE_BASE = {
      stroke: "#000",
      strokeWidth: "3px",
      fill: "#84e1ce",
    };
    const STYLE_HOLE_SELECTED = {
      stroke: "#ff0000",
      strokeWidth: "4px",
      fill: "#84e1ce",
      cursor: "move",
    };
    const STYLE_ARC_BASE = {
      stroke: "rgba(0, 0, 0, 0.5)",
      strokeWidth: "3px",
      strokeDasharray: "5,5",
      fill: "none",
    };
    const STYLE_ARC_SELECTED = {
      stroke: "#ff0000",
      strokeWidth: "4px",
      strokeDasharray: "5,5",
      fill: "none",
      cursor: "move",
    };

    // let epsilon = 7;
    let epsilon = 10;

    let flip = element.properties.get("flip_horizontal");
    let vFlip = element.properties.get("flip_vertical");

    let holeWidth = element.properties.get("width").get("length");
    let holePath = `M${0} ${-epsilon}  L${holeWidth} ${-epsilon}  L${holeWidth} ${epsilon}  L${0} ${epsilon}  z`;
    let arcPath = `M${0},${0}  A${holeWidth / 2},${holeWidth / 2} 0 0,1 ${
      holeWidth / 2
    },${holeWidth / 2}`;
    let arcPath2 = `M${0},${0}  A${holeWidth / 4 + holeWidth / 4},${
      holeWidth / 4 + holeWidth / 4
    } 0 0,0 ${holeWidth / 4 + holeWidth / 4},${holeWidth / 4 + holeWidth / 4}`;
    let holeStyle = element.selected ? STYLE_HOLE_SELECTED : STYLE_HOLE_BASE;
    let arcStyle = element.selected ? STYLE_ARC_SELECTED : STYLE_ARC_BASE;
    let length = element.properties.get("width").get("length");

    if (flip || vFlip) {
      return (
        <g
          transform={`translate(${
            -element.properties.get("width").get("length") / 2
          }, 0)`}
        >
          <path
            key="1"
            d={arcPath}
            style={arcStyle}
            transform={`translate(${0},${-holeWidth / 2})`}
          />
          <line
            key="2"
            x1={0}
            y1={0 - epsilon}
            x2={0}
            y2={-holeWidth / 2}
            style={holeStyle}
          />
          <path
            key="3"
            d={arcPath2}
            style={arcStyle}
            transform={`translate(${holeWidth},${
              -holeWidth / 4 - holeWidth / 4
            }) rotate(90)`}
          />
          <line
            key="4"
            x1={holeWidth}
            y1={0 - epsilon}
            x2={holeWidth}
            y2={-holeWidth / 4 - holeWidth / 4}
            style={holeStyle}
          />
          <path key="5" d={holePath} style={holeStyle} />
        </g>
      );
    } else {
      return (
        <g
          transform={`translate(${
            -element.properties.get("width").get("length") / 2
          }, 0)`}
        >
          <path
            key="1"
            d={arcPath}
            style={arcStyle}
            transform={`translate(${holeWidth},${holeWidth / 2}) rotate(180)`}
          />
          <line
            key="2"
            x1={0}
            y1={0 - epsilon}
            x2={0}
            y2={holeWidth / 4 + holeWidth / 4}
            style={holeStyle}
          />
          <path
            key="3"
            d={arcPath2}
            style={arcStyle}
            transform={`translate(${0},${
              holeWidth / 4 + holeWidth / 4
            }) rotate(270)`}
          />
          <line
            key="4"
            x1={holeWidth}
            y1={0 - epsilon}
            x2={holeWidth}
            y2={holeWidth / 2}
            style={holeStyle}
          />
          <path key="5" d={holePath} style={holeStyle} />
        </g>
      );
    }
  },

  render3D: function (element, layer, scene) {
    let w = element.properties.getIn(["width", "length"]);
    let h = element.properties.getIn(["height", "length"]);
    let WallThickeness = element.properties.getIn(["thickness", "length"]);
    let d = 7;

    let frame = new Three.Group();

    let materialFrame = new Three.MeshBasicMaterial({
      color: 0x383e42,
    });
    let materialGlass = new Three.MeshBasicMaterial({
      color: 0x6e95e1,
    });    

    function createOuterFrame(width, height, depth, position) {
      let geometry = new Three.BoxGeometry(width, height, depth);
      let mesh = new Three.Mesh(geometry, materialFrame);
      mesh.position.set(position.x, position.y, position.z);

      frame.add(mesh);
    }
    CreateOuterFrameBars(w, d, WallThickeness, h);

    function CreateOuterFrameBars(w1, d1, WallThickeness1, h1) {
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: h1 / 2 - d1 / 2, z: 0 });
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: -h1 / 2 + d1 / 2, z: 0 });
      createOuterFrame(d1, h1 - 2 * d1, WallThickeness1, {x: -w1 / 2 + d1 / 2,y: 0,z: 0,});
      createOuterFrame(d1, h1 - 2 * d1, WallThickeness1, {x: w1 / 2 - d1 / 2,y: 0,z: 0,});


      //inner top and bottom
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: (h1 / 2 - d1 / 2)-d1, z: 0 });
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: (-h1 / 2 + d1 / 2)-d1, z: 0 });

    }

    function CreateInnerFrameBars(w1, d1, WallThickeness1, h1, positionX) {


//left and right bars
      createOuterFrame(d1, h1, WallThickeness1, {x: -positionX,y: 0-(d1/2),z: 0,});
      createOuterFrame(d1, h1, WallThickeness1, {x: positionX,y: 0-(d1/2),z: 0,});

      

    }

    
    function createFrameGlass(width, height, depth) {
      let geometryGlass = new Three.BoxGeometry(width, height, depth / 5);
      let meshGlass = new Three.Mesh(geometryGlass, materialGlass);
      frame.add(meshGlass);
    }

    createFrameGlass(w, h, WallThickeness / 5);

    
    function createInnerFrames(d1, WallThickeness1, h1, totalWidth, panelCount) {

      let panelWidth = (totalWidth-(d*2)) / panelCount;
      for (let i = 0; i < panelCount; i++) {

        let positionX = -totalWidth / 2 + panelWidth / 2 + i * panelWidth;

        CreateInnerFrameBars(panelWidth, d1, WallThickeness1, h1-(d1*2) , d1+positionX+(panelWidth/2));

      }
    }

    createInnerFrames(d, WallThickeness, h, w, 2); 



    return Promise.resolve(frame);
  },


};
