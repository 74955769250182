let isLoading = false;

function setLoading(value) {
  isLoading = value;
}

function getLoading() {
  return isLoading;
}

export { setLoading, getLoading };