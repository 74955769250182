import React from 'react';
import * as Three from 'three';
import { loadObjWithMaterial } from '../../utils/load-obj';

let cached3DDoor = null;

export default {
  name: 'door-single',
  prototype: 'holes',

  info: {
    title: 'Single Door',
    tag: ['door'],
    description: 'Door',
    image: require('./door.png')
  },

  properties: {
    width: {
      label: 'Width',
      type: 'length-measure',
      hide: false,
      defaultValue: {
        length: 90
      }
    },
    height: {
      label: 'Height',
      type: 'length-measure',
      hide: true,
      defaultValue: {
        length: 200
      }
    },
    altitude: {
      label: 'Altitude',
      type: 'length-measure',
      hide: true,
      defaultValue: {
        length: 15,
      }
    },
    thickness: {
      label: 'Thickness',
      type: 'length-measure',
      hide: true,
      defaultValue: {
        length: 20
      }
    },
    flip_horizontal: {
      label: 'Horizontal Flip',
      type: 'checkbox',
      defaultValue: false,
      hide: false,
      values: {
        'none': false,
        'yes': true
      }
    },
    flip_vertical: {
      label: 'Vertical Flip',
      type: 'checkbox',
      defaultValue: false,
      hide: false,
      values: {
        'none': false,
        'yes': true
      }
    },
  },

  render2D: function (element, layer, scene) {
    const STYLE_HOLE_BASE = { stroke: '#000', strokeWidth: '3px', fill: '#84e1ce' };
    const STYLE_HOLE_SELECTED = { stroke: '#0096fd', strokeWidth: '4px', fill: '#84e1ce', cursor: 'move' };
    const STYLE_ARC_BASE = { stroke: "rgba(0, 0, 0, 0.5)", strokeWidth: '3px', strokeDasharray: '5,5', fill: 'none' };
    const STYLE_ARC_SELECTED = { stroke: '#0096fd', strokeWidth: '4px', strokeDasharray: '5,5', fill: 'none', cursor: 'move' };
    // const EPSILON = 3;
    // const EPSILON = 7;
    let EPSILON = 10;
    
    let hFlip = element.properties.get('flip_horizontal');
    let vFlip = element.properties.get('flip_vertical');
    let length = element.properties.get('width').get('length');
    let holePath = `M${0} ${-EPSILON}  L${length} ${-EPSILON}  L${length} ${EPSILON}  L${0} ${EPSILON}  z`;
    let holeStyle = element.selected ? STYLE_HOLE_SELECTED : STYLE_HOLE_BASE;
    let arcPath = `M${0},${0}  A${length},${length} 0 0,1 ${length},${length}`;
    let arcStyle = element.selected ? STYLE_ARC_SELECTED : STYLE_ARC_BASE;

    let scaleX, scaleY;
    let rotateAngle;
    let tX, tY;
    let pX1, pX2, pY1, pY2;

    if (hFlip) {
      scaleX = 1;
      if (vFlip) {
        tX = length;
        tY = -length;
        pX1 = -length;
        pY1 = 0;
        pX2 = -length;
        pY2 = length;
        rotateAngle = 180;
        scaleY = -1;
      }
      else {
        tX = 0;
        tY = -length;
        pX1 = 0;
        pY1 = 0;
        pX2 = 0;
        pY2 = -length;
        scaleY = 1;
        rotateAngle = 0;
      }
    }
    else {
      scaleX = -1;
      if (vFlip) {
        tX = 0;
        tY = 0;
        pX1 = length;
        pY1 = 0;
        pX2 = length;
        pY2 = length;
        rotateAngle = 90;
        scaleY = 1;
      }
      else {
        tX = length;
        tY = 0;
        pX1 = 0;
        pY1 = 0;
        pX2 = 0;
        pY2 = -length;
        rotateAngle = -90;
        scaleY = -1;
      }
    }

    return (
      <g transform={`translate(${-length / 2}, 0)`}>
        <path d={arcPath} style={arcStyle} transform={`translate(${tX},${tY}) scale(${scaleX},${scaleY}) rotate(${rotateAngle})`} />
        <line x1={pX1} y1={pY1 - EPSILON} x2={pX2} y2={pY2 - EPSILON} style={holeStyle} transform={`scale(${-scaleX},${scaleY})`} />
        <path d={holePath} style={holeStyle} />
      </g>
    );
  },




  render3D: function (element, layer, scene) {
    let w = element.properties.getIn(["width", "length"]);
    let h = element.properties.getIn(["height", "length"]);
    let WallThickeness = element.properties.getIn(["thickness", "length"]);
    let d = 7;

    let frame = new Three.Group();

    let materialFrame = new Three.MeshBasicMaterial({
      color: 0x383e42,
    });
    let materialGlass = new Three.MeshBasicMaterial({
      color: 0x6e95e1,
    });    

    function createOuterFrame(width, height, depth, position) {
      let geometry = new Three.BoxGeometry(width, height, depth);
      let mesh = new Three.Mesh(geometry, materialFrame);
      mesh.position.set(position.x, position.y, position.z);

      frame.add(mesh);
    }
    CreateOuterFrameBars(w, d, WallThickeness, h);

    function CreateOuterFrameBars(w1, d1, WallThickeness1, h1) {
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: h1 / 2 - d1 / 2, z: 0 });
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: -h1 / 2 + d1 / 2, z: 0 });
      createOuterFrame(d1, h1 - 2 * d1, WallThickeness1, {x: -w1 / 2 + d1 / 2,y: 0,z: 0,});
      createOuterFrame(d1, h1 - 2 * d1, WallThickeness1, {x: w1 / 2 - d1 / 2,y: 0,z: 0,});


      //inner top and bottom
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: (h1 / 2 - d1 / 2)-d1, z: 0 });
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: (-h1 / 2 + d1 / 2)-d1, z: 0 });

    }

    function CreateInnerFrameBars(w1, d1, WallThickeness1, h1, positionX) {


//left and right bars
      createOuterFrame(d1, h1, WallThickeness1, {x: -positionX,y: 0-(d1/2),z: 0,});
      createOuterFrame(d1, h1, WallThickeness1, {x: positionX,y: 0-(d1/2),z: 0,});

      

    }

    
    function createFrameGlass(width, height, depth) {
      let geometryGlass = new Three.BoxGeometry(width, height, depth / 5);
      let meshGlass = new Three.Mesh(geometryGlass, materialGlass);
      frame.add(meshGlass);
    }

    createFrameGlass(w, h, WallThickeness / 5);

    
    function createInnerFrames(d1, WallThickeness1, h1, totalWidth, panelCount) {

      let panelWidth = (totalWidth-(d*2)) / panelCount;
      for (let i = 0; i < panelCount; i++) {

        let positionX = -totalWidth / 2 + panelWidth / 2 + i * panelWidth;

        CreateInnerFrameBars(panelWidth, d1, WallThickeness1, h1-(d1*2) , d1+positionX+(panelWidth/2));

      }
    }

    createInnerFrames(d, WallThickeness, h, w, 1); 



    return Promise.resolve(frame);
  },

};
