


const claddingTextures = {
    cedarVertical: {
        name: 'Redwood Cedar',
        imageName: 'VerticalCedar.jpeg',
        lengthRepeatScale: 0.007,
        heightRepeatScale: .001,
        normal: {
            lengthRepeatScale: 0.007,
            heightRepeatScale: 0.001,
        }
    },
    cedarHorizontal: {
        name: 'Redwood Cedar Horizontal',
        imageName: 'HorizontalCedar.jpeg',
        lengthRepeatScale: 0.001,
        heightRepeatScale: .007,
        normal: {
            lengthRepeatScale: 0.007,
            heightRepeatScale: 0.001,
        }
    },
    larchVertical: {
        name: 'Larch - Vertical',
        imageName: 'VerticalLarch.jpg',
        lengthRepeatScale: 0.007,
        heightRepeatScale: .001,
        normal: {
            lengthRepeatScale: 0.007,
            heightRepeatScale: 0.001,
        }
    },
    larchHorizontal: {
        name: 'Larch - Horizontal',
        imageName: 'HorizontalLarch.jpg',
        lengthRepeatScale: 0.001,
        heightRepeatScale: .007,
        normal: {
            lengthRepeatScale: 0.007,
            heightRepeatScale: 0.001,
        }
    },
    thermoWoodVertical: {
        name: 'ThermoWood - Vertical',
        imageName: 'VerticalThermoWood.jpg',
        lengthRepeatScale: 0.007,
        heightRepeatScale: .001,
        normal: {
            lengthRepeatScale: 0.007,
            heightRepeatScale: 0.001,
        }
    },
    thermoWoodHorizontal: {
        name: 'ThermoWood - Horizontal',
        imageName: 'HorizontalThermoWood.jpg',
        lengthRepeatScale: 0.001,
        heightRepeatScale: .007,
        normal: {
            lengthRepeatScale: 0.007,
            heightRepeatScale: 0.001,
        }
    },

};



export let selectedCladding = claddingTextures.cedarVertical;

// export function setSelectedCladding(cladding) {
//     selectedCladding = cladding;
// }


export function setSelectedCladdingFromMenu(cladding) {

    for (const key in claddingTextures) {
        if (claddingTextures[key].name === cladding) {
            selectedCladding = (claddingTextures[key]);
            // return claddingTextures[key];
        }
    }
    return claddingTextures.cedarVertical;
}






export default claddingTextures;
