import React from "react";
import { buildWall, updatedWall } from "./wall-factory-3d";
import * as SharedStyle from "../../styles/shared-style";
import * as Geometry from "../../utils/geometry";
import Translator from "../../translator/translator";

// import claddingTextures, {
//   setSelectedCladding,
//   selectedCladding,
// } from "./claddingConfig";

const epsilon = 20;
const EPSILON = 20;
const EXTRA_EPSILON = 5;
const THRESHOLD_ANGLE = 0.5;
const EPSILON_ANGLE = 0.01;
const CHAR_HEIGHT = 11;

const STYLE_TEXT = { textAnchor: "middle" };
const STYLE_LINE = { stroke: SharedStyle.LINE_MESH_COLOR.selected };
// const STYLE_RECT = { strokeWidth: 1, stroke: SharedStyle.LINE_MESH_COLOR.unselected, fill: '#8E9BA2' };
const STYLE_RECT = {
  strokeWidth: 1,
  stroke: SharedStyle.LINE_MESH_COLOR.unselected,
};
const STYLE_RECT_SELECTED = {
  ...STYLE_RECT,
  stroke: SharedStyle.LINE_MESH_COLOR.selected,
  fill: "#E5E5E5 ",
};

let translator = new Translator();

export default function WallFactory(name, info, textures) {
  let wallElement = {
    name,
    prototype: "lines",
    info,
    properties: {
      height: {
        label: translator.t("height"),
        type: "length-measure",
        hide: true,
        defaultValue: {
          length: 230,
        },
      },
      thickness: {
        label: translator.t("thickness"),
        type: "length-measure",
        
      hide: true,
        defaultValue: {
          length: 20,
        },
      },
    },

    render2D: function (element, layer, scene) {
      let { x: x1, y: y1 } = layer.vertices.get(element.vertices.get(0));
      let { x: x2, y: y2 } = layer.vertices.get(element.vertices.get(1));

      let length = Geometry.pointsDistance(x1, y1, x2, y2);
      let length_5 = length / 5;

      let thickness = element.getIn(["properties", "thickness", "length"]);
      let half_thickness = thickness / 2;
      let half_thickness_eps = half_thickness + epsilon;
      let char_height = 11;
      let extra_epsilon = 5;
      let textDistance = half_thickness + epsilon + extra_epsilon;

      let fill = "#8E9BA2";
      if (name.includes("internal")) {
        fill = "#fff";
      }

      return element.selected ? (
        <g>
          <rect
            x={-half_thickness}
            y={-half_thickness}
            width={length + 2 * half_thickness}
            height={thickness}
            style={STYLE_RECT_SELECTED}
          />
        </g>
      ) : (
        <rect
          x={-half_thickness}
          y={-half_thickness}
          width={length + 2 * half_thickness}
          height={thickness}
          fill={fill}
          style={STYLE_RECT}
        />
      );
    },

    render3D: function (element, layer, scene) {
      return buildWall(element, layer, scene, textures);
    },

    updateRender3D: (
      element,
      layer,
      scene,
      mesh,
      oldElement,
      differences,
      selfDestroy,
      selfBuild
    ) => {
      return updatedWall(
        element,
        layer,
        scene,
        textures,
        mesh,
        oldElement,
        differences,
        selfDestroy,
        selfBuild
      );
    },
  };

  if (textures) {
    let textureValues = { none: "None" };

    for (let textureName in textures) {
      textureValues[textureName] = textures[textureName].name;
    }

    // for (let textureName in claddingTextures) {
    //   // textureValues[textureName] = textures[textureName].name;
    // }

    if (name.includes("internal")) {
      wallElement.properties.textureA = {
        label: translator.t("texture") + " A",
        type: "enum",
        hide: true,
        defaultValue: textureValues.painted ? "painted" : "None",
        values: textureValues,
      };

      wallElement.properties.textureB = {
        label: translator.t("texture") + " B",
        type: "enum",
        hide: true,
        defaultValue: textureValues.painted ? "painted" : "None",
        values: textureValues,
      };
    } else {
      wallElement.properties.textureA = {
        label: translator.t("texture") + " A",
        type: "enum",
        hide: true,
        defaultValue: textureValues.cedarVertical ? "cedarVertical" : "None",
        values: textureValues,
      };

      wallElement.properties.textureB = {
        label: translator.t("texture") + " B",
        type: "enum",
        hide: true,
        defaultValue: textureValues.cedarVertical ? "cedarVertical" : "None",
        values: textureValues,
      };

      // wallElement.properties.textureA = {
      //   label: translator.t('texture') + ' A',
      //   type: 'enum',
      //   defaultValue: selectedCladding ? textureValues[selectedCladding] : textureValues[0],
      //   values: textureValues
      // };

      // wallElement.properties.textureB = {
      //   label: translator.t('texture') + ' B',
      //   type: 'enum',
      //   defaultValue: selectedCladding ? textureValues[selectedCladding] :  textureValues[0],
      //   values: textureValues
      // };

      // wallElement.properties.textureA = {
      //   label: translator.t('texture') + ' A',
      //   type: 'enum',
      //   defaultValue: claddingConfig[selectedCladding].textureA,
      //   values: textureValues
      // };

      // wallElement.properties.textureB = {
      //   label: translator.t('texture') + ' B',
      //   type: 'enum',
      //   defaultValue: claddingConfig[selectedCladding].textureB,
      //   values: textureValues
      // };
    }
  }

  return wallElement;
}
