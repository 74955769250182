import React from "react";
import * as Three from "three";
import { loadObjWithMaterial } from "../../utils/load-obj";

let cached3DDoor = null;

export default {
  name: "door-bifold3",
  prototype: "holes",

  info: {
    title: "Bifold Door - 3 Doors",
    tag: ["door"],
    description: "Door",
    image: require("./door.png"),
  },

  properties: {
    width: {
      label: "Width",
      type: "length-measure",
      defaultValue: {
        length: 240,
      },
    },
    height: {
      label: "Height",
      type: "length-measure",
      hide: true,
      defaultValue: {
        length: 200,
      },
    },
    altitude: {
      label: "Altitude",
      type: "length-measure",
      hide: true,
      defaultValue: {
        length: 15,
      },
    },
    thickness: {
      label: "Thickness",
      type: "length-measure",
      hide: true,
      defaultValue: {
        length: 20,
      },
    },
    flip_horizontal: {
      label: "Horizontal Flip",
      type: "checkbox",
      defaultValue: false,
      values: {
        none: false,
        yes: true,
      },
    },
    flip_vertical: {
      label: "Vertical Flip",
      type: "checkbox",
      defaultValue: false,
      values: {
        none: false,
        yes: true,
      },
    },
  },
  render2D: function (element, layer, scene) {
    const STYLE_HOLE_BASE = {
      stroke: "rgba(0, 0, 0, 0.5)",
      strokeWidth: "10px",
      fill: "rgba(100, 100, 100, 0.5)",
    };

    const STYLE_HOLE_SELECTED = {
      stroke: "#0096fd",
      strokeWidth: "10px",
      cursor: "move",
    };

    let flip = element.properties.get("flip_horizontal");
    let vFlip = element.properties.get("flip_vertical");

    // const epsilon = 3.5;
    let epsilon = 5;
    let holeWidth = element.properties.get("width").get("length");

    let thirdWidth = holeWidth / Math.sqrt(2) / 3;

    let holeStyle = element.selected ? STYLE_HOLE_SELECTED : STYLE_HOLE_BASE;

    let rightPanelPath = `M${holeWidth / Math.sqrt(2)} 0 L${
      2 * thirdWidth
    } ${-thirdWidth}`;
    let middlePanelPath = `M${2 * thirdWidth} ${-thirdWidth} L${thirdWidth} 0`;
    let leftPanelPath = `M${thirdWidth} 0 L0 ${-thirdWidth}`;

    if (flip) {
      const additionalOffset = thirdWidth / 4; // Adjust this value as needed

      rightPanelPath = `M${thirdWidth + additionalOffset} 0 L${
        2 * thirdWidth + additionalOffset
      } ${-thirdWidth}`;
      middlePanelPath = `M${
        2 * thirdWidth + additionalOffset
      } ${-thirdWidth} L${3 * thirdWidth + additionalOffset} 0`;
      leftPanelPath = `M${3 * thirdWidth + additionalOffset} 0 L${
        holeWidth / Math.sqrt(2) + thirdWidth + additionalOffset
      } ${-thirdWidth}`;
    }

    if (vFlip) {
      const vFlipAdjustment = -thirdWidth*2;

      rightPanelPath = `M${holeWidth / Math.sqrt(2)} ${vFlipAdjustment} L${2 * thirdWidth} ${-0 - thirdWidth}`;
      middlePanelPath = `M${2 * thirdWidth} ${-0 - thirdWidth} L${thirdWidth} ${vFlipAdjustment}`;
      leftPanelPath = `M${thirdWidth} ${vFlipAdjustment} L0 ${vFlipAdjustment + thirdWidth      }`;
    }
    if (flip && vFlip) {

      const additionalOffset = thirdWidth / 4; 
      const vFlipAdjustment = -thirdWidth * 2; 
  
      rightPanelPath = `M${2*thirdWidth + additionalOffset} ${vFlipAdjustment/2} L${thirdWidth + additionalOffset} ${- 2*thirdWidth }`;
      middlePanelPath = `M${2 * thirdWidth + additionalOffset} ${-thirdWidth} L${3 * thirdWidth + additionalOffset} ${vFlipAdjustment}`;
      leftPanelPath = `M${3 * thirdWidth + additionalOffset} ${vFlipAdjustment} L${holeWidth / Math.sqrt(2) + thirdWidth + additionalOffset      } ${vFlipAdjustment + thirdWidth}`;
  }
    let backgroundRect = (
      <rect
        key="0"
        x={0}
        y={-epsilon * 2 - thirdWidth}
        width={holeWidth}
        height={epsilon * 4}
        fill="white"
      />
    );

    return (
      <g transform={`translate(${-holeWidth / 2}, ${thirdWidth})`}>
        {backgroundRect}
        <path key="1" d={leftPanelPath} style={holeStyle} />
        <path key="2" d={middlePanelPath} style={holeStyle} />
        <path key="3" d={rightPanelPath} style={holeStyle} />
      </g>
    );
  },

  // render3D: function (element, layer, scene) {
  //   let onLoadItem = (object) => {
  //     let boundingBox = new Three.Box3().setFromObject(object);

  //     let initialWidth = boundingBox.max.x - boundingBox.min.x;
  //     let initialHeight = boundingBox.max.y - boundingBox.min.y;
  //     let initialThickness = boundingBox.max.z - boundingBox.min.z;

  //     if (element.selected) {
  //       let box = new Three.BoxHelper(object, 0x99c3fb);
  //       box.material.linewidth = 2;
  //       box.material.depthTest = false;
  //       box.renderOrder = 1000;
  //       object.add(box);
  //     }

  //     let width = element.properties.get("width").get("length");
  //     let height = element.properties.get("height").get("length");
  //     let thickness = element.properties.get("thickness").get("length");

  //     object.scale.set(
  //       width / initialWidth,
  //       height / initialHeight,
  //       thickness / initialThickness
  //     );

  //     return object;
  //   };

  //   if (cached3DDoor) {
  //     return Promise.resolve(onLoadItem(cached3DDoor.clone()));
  //   }

  //   let mtl = require("./door.mtl");
  //   let obj = require("./door.obj");
  //   let img = require("./texture.jpg");

  //   return loadObjWithMaterial(mtl, obj, img).then((object) => {
  //     cached3DDoor = object;
  //     return onLoadItem(cached3DDoor.clone());
  //   });
  // },
  

  render3D: function (element, layer, scene) {
    let w = element.properties.getIn(["width", "length"]);
    let h = element.properties.getIn(["height", "length"]);
    let WallThickeness = element.properties.getIn(["thickness", "length"]);
    let d = 7;

    let frame = new Three.Group();

    let materialFrame = new Three.MeshBasicMaterial({
      color: 0x383e42,
    });
    let materialGlass = new Three.MeshBasicMaterial({
      color: 0x6e95e1,
    });    

    function createOuterFrame(width, height, depth, position) {
      let geometry = new Three.BoxGeometry(width, height, depth);
      let mesh = new Three.Mesh(geometry, materialFrame);
      mesh.position.set(position.x, position.y, position.z);

      frame.add(mesh);
    }
    CreateOuterFrameBars(w, d, WallThickeness, h);

    function CreateOuterFrameBars(w1, d1, WallThickeness1, h1) {
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: h1 / 2 - d1 / 2, z: 0 });
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: -h1 / 2 + d1 / 2, z: 0 });
      createOuterFrame(d1, h1 - 2 * d1, WallThickeness1, {x: -w1 / 2 + d1 / 2,y: 0,z: 0,});
      createOuterFrame(d1, h1 - 2 * d1, WallThickeness1, {x: w1 / 2 - d1 / 2,y: 0,z: 0,});


      //inner top and bottom
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: (h1 / 2 - d1 / 2)-d1, z: 0 });
      createOuterFrame(w1, d1, WallThickeness1, { x: 0, y: (-h1 / 2 + d1 / 2)-d1, z: 0 });

    }

    function CreateInnerFrameBars(w1, d1, WallThickeness1, h1, positionX) {


//left and right bars
      createOuterFrame(d1, h1, WallThickeness1, {x: -positionX,y: 0-(d1/2),z: 0,});
      createOuterFrame(d1, h1, WallThickeness1, {x: positionX,y: 0-(d1/2),z: 0,});

      

    }

    
    function createFrameGlass(width, height, depth) {
      let geometryGlass = new Three.BoxGeometry(width, height, depth / 5);
      let meshGlass = new Three.Mesh(geometryGlass, materialGlass);
      frame.add(meshGlass);
    }

    createFrameGlass(w, h, WallThickeness / 5);

    
    function createInnerFrames(d1, WallThickeness1, h1, totalWidth, panelCount) {

      let panelWidth = (totalWidth-(d*2)) / panelCount;
      for (let i = 0; i < panelCount; i++) {

        let positionX = -totalWidth / 2 + panelWidth / 2 + i * panelWidth;

        CreateInnerFrameBars(panelWidth, d1, WallThickeness1, h1-(d1*2) , d1+positionX+(panelWidth/2));

      }
    }

    createInnerFrames(d, WallThickeness, h, w, 3); 



    return Promise.resolve(frame);
  },




};
