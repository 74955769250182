import {SELECT_TOOL_3D_VIEW,SELECT_TOOL_ELEVATIONS, SELECT_TOOL_3D_FIRST_PERSON} from '../utils/constants';



export function selectTool3DView() {
  return {
    type: SELECT_TOOL_3D_VIEW
  }
}

export function selectToolElevations() {
  return {
    type: SELECT_TOOL_ELEVATIONS
  }
}


export function selectTool3DFirstPerson() {
  return {
    type: SELECT_TOOL_3D_FIRST_PERSON
  }
}


