import WallFactory from './wall-factory';
import AreaFactory from './area-factory';
import AreaFactoryRoof from './area-factory3dRoof';

export {
  WallFactory,
  AreaFactory,
  AreaFactoryRoof
};

export default {
  WallFactory,
  AreaFactory,
  AreaFactoryRoof
};
